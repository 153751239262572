<template>
  <div class="personal-profile-box">
    <div class="personal-profile-show">
      <div class="personal-profile-show-top">
        <div class="personal-profile-avatar-box">
          <img class="personal-profile-avatar" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng068dd605ef9cf7c4866281a28301ef607721b1e9a56a591a65f512afaa0f607a" alt="个人头像">
        </div>
        <div class="personal-profile-avatar-modify-btn">
          <p class="personal-profile-avatar-modify-btn-text">修改头像</p>
        </div>
      </div>
      <div class="personal-profile-show-bottom">
        <ul>
          <li>
            <p class="personal-profile-title-modify">昵称</p>
            <input class="personal-profile-value-modify-input" type="text" placeholder="请输入您的昵称">
          </li>
          <li>
            <p class="personal-profile-title-modify">微信号</p>
            <input class="personal-profile-value-modify-input" type="text" placeholder="请输入您的微信号">
          </li>
          <li>
            <p class="personal-profile-title-modify">QQ号</p>
            <input class="personal-profile-value-modify-input" type="text" placeholder="请输入您的QQ号">
          </li>
        </ul>
      </div>
      <div class="personal-profile-save-btn">
        <p class="personal-profile-save-btn-text">保存</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.personal-profile-box{
  width: 1140px;
  margin-top: 20px;
  margin-left: 30px;
  .personal-profile-show{
    width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .personal-profile-show-top{
      width: 940px;
      height: 80px;
      display: flex;
      align-items: center;
      .personal-profile-avatar-box{
        width: 80px;
        height: 80px;
        .personal-profile-avatar{
          width: 80px;
          height: 80px;
          border-radius: 40px;
        }
      }
      .personal-profile-avatar-modify-btn{
        margin-left: 20px;
        width: 120px;
        height: 46px;
        background: #F6F7FF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .personal-profile-avatar-modify-btn-text{
          height: 14px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 14px;
          color: #222222;
          line-height: 14px;
          text-align: right;
          font-style: normal;
        }
      }
    }
    .personal-profile-show-bottom{
      width: 1140px;
      ul{
        width: 1140px;
        list-style-type: none;
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        li{
          list-style: none;
          width: 420px;
          height: 70px;
          margin-top: 30px;
          margin-left: 100px;
          .personal-profile-title-modify{
            height: 14px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #555555;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            cursor: default;
          }
          .personal-profile-value-modify-input{
            padding-left: 14px;
            margin-top: 10px;
            width: 420px;
            height: 46px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #F0F0F0;
            outline: none;
            &::placeholder{
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #CCCCCC;
              line-height: 14px;
              text-align: left;
              font-style: normal;
            }
          }
        }
      }
    }
    .personal-profile-save-btn{
      margin-top: 50px;
      width: 200px;
      height: 46px;
      background: #F02233;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: default;
      .personal-profile-save-btn-text{
        height: 14px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 14px;
        text-align: right;
        font-style: normal;
      }
    }
 
  }
}
</style>